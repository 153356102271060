import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import SEO from 'components/shared/SEO';
import LazyImage from 'components/shared/LazyImage';
import Icon from 'components/shared/Icon';
import Button from 'components/shared/Button';
import Heading from 'components/shared/Heading';
import Content from 'components/shared/Content';
import invIcon from 'assets/icons/logo.svg';

const Wrapper = styled.header`
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 650px;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.1)
    );
    z-index: 1;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const TitleWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.colors.white};
  z-index: 3;
  text-align: center;
`;

const StyledButton = styled(Button)`
  max-width: 250px;
  padding: 20px 20px;
  &:hover {
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.pink};
  }
  ${({ theme }) => theme.mq.s} {
    padding: 25px 20px;
    max-width: 300px;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: -80px;
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
`;

const NotFoundPage = ({ data }) => {
  const heroImg = data.heroImg.childImageSharp.fluid;

  return (
    <>
      <SEO title="Nie znaleziono - Grzybowska 37" />
      <Wrapper>
        <ImageWrapper>
          <LazyImage src={heroImg} alt="hero image" cover />
        </ImageWrapper>
        <TitleWrapper>
          <Content>
            <Heading
              dangerouslySetInnerHTML={{
                __html: '404',
              }}
            />
            <Heading
              dangerouslySetInnerHTML={{
                __html: 'Nie znaleziono <br/><span>strony</span>',
              }}
            />
            <br />
            <StyledButton as={Link} to="/">
              Strona główna
            </StyledButton>
            <IconWrapper>
              <Icon full src={invIcon} alt="grzybowska 37" />
            </IconWrapper>
          </Content>
        </TitleWrapper>
      </Wrapper>
    </>
  );
};

NotFoundPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export const query = graphql`
  query queryNotFoundPage {
    heroImg: file(name: { eq: "hero_img" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default NotFoundPage;
